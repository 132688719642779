.App {
  position: relative;
  min-height: 100vh;
}

.content-wrap {
  padding-bottom: 10rem;
  background: rgb(224, 241, 247);
  background: linear-gradient(45deg, rgba(224, 241, 247, 1) 0%, rgba(243, 255, 251, 1) 66%, rgba(240, 240, 240, 1) 100%);
}

h5 {
  font-weight: 100;
  line-height: 1.5;
}

#main-nav {
  flex-direction: row-reverse;
}

@media (min-width:991px) {
  #main-nav {
    flex-direction: row;
  }
}

#hero {
  background-size: contain;
  background-position: top right;
  background-repeat: no-repeat;
  color: rgb(65, 65, 65);
  min-height: 45vh;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

#about {
  margin-bottom:4rem;
}

#about p {
  font-size: 1.5rem;
}

#projects {
  min-height: 85vh;
}

#links {
  min-height: 50vh;
}

#footer {
  background-color: none;
  color: var(--dark);
}

#profile-img {
  max-width: 300px;
}

#footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 10rem;
}

.navbar {
  transition: all 1s;
}

.navbar.active {
  background-color: rgba(255, 255, 255, 0.95);
  transition: all .5s;
  box-shadow: 0 3px 10px #292a3318;
}

.nav-item {
  margin-left: 2rem;
  font-size: 1.25rem;
}

.title {
  padding-bottom: 2rem;
  font-size: 4rem;
  font-weight: bold;
}

.row {
  margin-bottom: 1rem;
}

.flex-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.project-clicked {
  box-sizing: border-box;
  min-height: 250px;
  background: var(--dark);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  margin: 15px -15px;
  border-radius: 1rem;
  flex-direction: column;
  padding: 1rem 4rem 0;
  transition: all .5s;
}

.project-clicked .btn {
  margin: .25rem;
}

.hide {
  display: none;
}

@media (min-width:1200px) {
  .container {
    max-width: 1500px;
  }
}